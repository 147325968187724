import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Button } from '@wartek-id/button-v1'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from '@wartek-id/dialog-v1'
import { Icon } from '@wartek-id/icon-v2'
import { Shimmer } from '@wartek-id/shimmer'
import { Text } from '@wartek-id/text-v1'

import { useAPIGetEvaluationDetail } from 'api/kinerja/useAPIKinerja'
import CircularProgress from 'app/KinerjaPage/components/CircularProgress'
import useDemoContext from 'app/KinerjaPage/hooks/useDemoContext'
import useSKPPelaksanaanTrackEvent from 'app/KinerjaPage/hooks/useSKPPelaksanaanTrackEvent'
import { GuidePerilakuKerjaDialog } from 'app/KinerjaPage/pages/PelaksanaanV2/components/GuidePerilakuKerjaDialog'
import { Header } from 'app/KinerjaPage/pages/PelaksanaanV2/components/Header'
import { PERFORMANCE_PRACTICE_RESULT_TEXT } from 'app/KinerjaPage/pages/PelaksanaanV2/constants/performancePractice'
import { PREDICATE_RECOMMENDATION_RESULT_TEXT } from 'app/KinerjaPage/pages/PelaksanaanV2/constants/predicateRecommendation'
import { WORK_BEHAVIOR_RESULT_TEXT } from 'app/KinerjaPage/pages/PelaksanaanV2/constants/workBehavior'
import { PENGELOLAAN_KINERJA_KS_PENILAI_PATH } from 'app/KinerjaShared/constants/path'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import CardFilledBySystem from 'app/KinerjaPage/pages/PelaksanaanV2/components/CardFilledBySystem'

import type {
  TPerformancePracticeResult,
  TPredicateRecommendationResult,
  TWorkBehaviorResult,
} from 'types/kinerja-pelaksanaan'

type RincianPenilaianKinerjaProps = {
  npsn: string
  guruPtkId: string
}
export const GUIDE_PERILAKU_KERJA_STORAGE_KEY =
  'GUIDE_PERILAKU_KERJA_ALWAYS_HIDE'

export function RincianPenilaianKinerja({
  npsn,
  guruPtkId,
}: RincianPenilaianKinerjaProps) {
  const isPredikatKinerjaEnabled =
    process.env.NEXT_PUBLIC_SKP_PREDIKAT_KINERJA_ENABLED === 'true'
  const isPredikatKinerjaResultEnabled =
    process.env.NEXT_PUBLIC_SKP_PREDIKAT_KINERJA_RESULT_ENABLED === 'true'

  const { isDemoMode } = useDemoContext()
  const { trackEvent } = useSKPPelaksanaanTrackEvent()
  const router = useRouter()

  const [showGuidePerilakuKerjaDialog, setShowGuidePerilakuKerjaDialog] =
    useState(false)
  const [
    alwaysHideGuidePerilakuKerjaDialog,
    setAlwaysHideGuidePerilakuKerjaDialog,
  ] = useLocalStorage(GUIDE_PERILAKU_KERJA_STORAGE_KEY, false)

  const { data: evaluationDetailData, isLoading: isLoadingEvaluationDetail } =
    useAPIGetEvaluationDetail({
      params: { ptkId: guruPtkId },
      isDemoMode,
    })

  const isFilledBySystem = evaluationDetailData?.data?.filledBySystem === true

  useEffect(() => {
    trackEvent({
      activity_type: 'penilaian_ks_open_rincian_penilaian',
      screen_name: 'ks_rincian_penilaian',
      event_trigger: 'ks_periksa_skp',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToPerilakuKerjaPage = () =>
    router.push(
      `${PENGELOLAAN_KINERJA_KS_PENILAI_PATH}/penilaian-kinerja/${npsn}/${guruPtkId}/perilaku-kerja`
    )

  const handleOpenPerilakuKerja = (isCompleted: boolean) => {
    trackEvent({
      activity_type: isCompleted
        ? 'penilaian_ks_rincian_penilaian_click_ubah_perilaku_kerja'
        : 'penilaian_ks_rincian_penilaian_click_nilai_perilaku_kerja',
      screen_name: 'ks_rincian_penilaian',
      event_trigger: 'ks_periksa_skp',
    })

    if (alwaysHideGuidePerilakuKerjaDialog) {
      goToPerilakuKerjaPage()
    } else {
      setShowGuidePerilakuKerjaDialog(true)
    }
  }

  const handleConfirmGuidePerilakuKerjaDialog = (alwaysHide?: boolean) => {
    if (alwaysHide) {
      setAlwaysHideGuidePerilakuKerjaDialog(true)
    }

    setShowGuidePerilakuKerjaDialog(false)
    goToPerilakuKerjaPage()
  }

  const isObservationCompleted =
    evaluationDetailData?.data?.observation?.progress >=
    evaluationDetailData?.data?.observation?.total

  const isDocumentCompleted =
    evaluationDetailData?.data?.document.progress >=
    evaluationDetailData?.data?.document.total

  const isPerilakuKerjaCompleted =
    evaluationDetailData?.data?.workBehavior?.progress >=
    evaluationDetailData?.data?.workBehavior?.total

  return (
    <div className="px-8 pb-8 pt-12 bg-gray-10 min-h-full">
      <div className="max-w-7xl mx-auto">
        <Header
          title="Rincian Penilaian Kinerja"
          className="mb-9"
          onBack={() => {
            router.back()
          }}
        />

        {isFilledBySystem && (
          <CardFilledBySystem
            className="mb-6"
            isGuru={false}
            storageId={guruPtkId}
            shouldInitiallyShowPopUp={true}
          />
        )}

        <section className="flex">
          <section className="flex flex-col justify-center space-y-6 w-96 flex-grow-0 flex-shrink-0 bg-gradient-to-t from-cyan-50 to-cyan-80 rounded-l pl-9 pr-10 py-9">
            <div className="space-y-3">
              <Icon fontSize="lg" className="!text-white">
                account_circle_outlined
              </Icon>
              {isLoadingEvaluationDetail ? (
                <>
                  <Shimmer height={32} width="100%" />
                  <Shimmer height={24} width="40%" />
                  <Shimmer height={24} width="60%" />
                </>
              ) : (
                <>
                  <Text
                    variant="heading-lg"
                    color="inverse"
                    className="overflow-wrap-anywhere"
                  >
                    {evaluationDetailData?.data?.name}
                  </Text>

                  <div className="overflow-wrap-anywhere">
                    <Text variant="body-lg" color="inverse">
                      {evaluationDetailData?.data?.class}
                    </Text>
                    <Text variant="body-lg" color="inverse">
                      {evaluationDetailData?.data?.subject}
                    </Text>
                  </div>
                </>
              )}
            </div>

            {isPredikatKinerjaEnabled && isPredikatKinerjaResultEnabled ? (
              <FinalPredicate
                isLoading={isLoadingEvaluationDetail}
                workBehaviorResult={
                  evaluationDetailData?.data?.workBehavior?.result
                }
                performancePracticeResult={
                  evaluationDetailData?.data?.performancePractice?.result
                }
                predicateRecommendationResult={
                  evaluationDetailData?.data?.predicateRecommendation
                }
              />
            ) : (
              <div className="p-3 rounded bg-white">
                <Text color="subdued" variant="body-sm">
                  Nanti Anda dapat melakukan penilaian akhir kinerja guru di
                  sini.
                </Text>
              </div>
            )}
          </section>

          <section className="bg-white w-full pt-9 pl-9 pr-11 pb-7 rounded-r space-y-5">
            <div className="space-y-1">
              <Text variant="heading-sm">Rincian Penilaian Kinerja:</Text>
              <Text color="subdued">
                Lakukan penilaian untuk Praktik Kinerja dan Perilaku Kerja,
                serta periksa kelengkapan dokumen.
              </Text>
            </div>

            <div className="space-y-6">
              {isLoadingEvaluationDetail ? (
                <>
                  <Shimmer width="100%" height={132} />
                  <Shimmer width="100%" height={132} />
                  <Shimmer width="100%" height={132} />
                </>
              ) : (
                <>
                  <div className="border border-subdued rounded py-6 pl-6 pr-8 space-y-6">
                    <div className="space-x-6 flex items-center">
                      <div className="w-20 h-20 flex-shrink-0 flex items-center justify-center">
                        <CircularProgress
                          value={
                            evaluationDetailData?.data?.observation?.progress ||
                            0
                          }
                          maxValue={
                            evaluationDetailData?.data?.observation?.total || 0
                          }
                        />
                      </div>
                      <div className="space-y-2 w-full">
                        <Text variant="heading-md" as="h2">
                          Praktik Kinerja
                        </Text>
                        <Text color="subdued">
                          Jadwal Observasi Praktik Kinerja:{' '}
                          {isFilledBySystem
                            ? '-'
                            : `${evaluationDetailData?.data?.observation?.date}.`}
                        </Text>
                      </div>
                      {!isFilledBySystem && (
                        <Link
                          href={`${PENGELOLAAN_KINERJA_KS_PENILAI_PATH}/pelaksanaan/beranda${
                            isDemoMode ? '/demo' : ''
                          }/${guruPtkId}`}
                          passHref
                        >
                          <Button
                            as="a"
                            className="flex-shrink-0"
                            size="lg"
                            color={isObservationCompleted ? 'white' : 'blue'}
                            disabled={
                              isNaN(
                                evaluationDetailData?.data?.observation?.total
                              ) ||
                              evaluationDetailData?.data?.observation?.total <=
                                0
                            }
                            onClick={() => {
                              trackEvent({
                                activity_type: isObservationCompleted
                                  ? 'penilaian_ks_rincian_click_cek_observasi_kelas'
                                  : 'penilaian_ks_rincian_penilaian_click_nilai',
                                screen_name: 'ks_rincian_penilaian',
                                event_trigger: 'ks_periksa_skp',
                              })
                            }}
                          >
                            {isObservationCompleted ? 'Cek' : 'Nilai'}
                            <span className="sr-only"> Praktik Kinerja</span>
                          </Button>
                        </Link>
                      )}
                    </div>

                    {isFilledBySystem && (
                      <div className="py-4 px-3 flex space-x-3 bg-surface-success-subdued rounded-md items-start">
                        <Icon
                          fontSize="sm"
                          className="!text-xl !leading-5"
                          color="success"
                        >
                          check_circle
                        </Icon>
                        <Text variant="body-sm">
                          Praktik Kinerja telah dinilai secara otomatis oleh
                          sistem.
                        </Text>
                      </div>
                    )}
                  </div>

                  {isPredikatKinerjaEnabled && (
                    <div className="border border-subdued rounded py-6 pl-6 pr-8 space-y-6">
                      <div className="space-x-6 flex items-center">
                        <div className="w-20 h-20 flex-shrink-0 flex items-center justify-center">
                          <CircularProgress
                            value={
                              evaluationDetailData?.data?.workBehavior
                                ?.progress || 0
                            }
                            maxValue={
                              evaluationDetailData?.data?.workBehavior?.total ||
                              0
                            }
                          />
                        </div>
                        <div className="space-y-2 w-full">
                          <Text variant="heading-md">Perilaku Kerja</Text>
                          <Text color="subdued">
                            Pantau dan lakukan penilaian untuk seluruh aspek
                            Perilaku Kerja.
                          </Text>
                        </div>

                        <Button
                          className="flex-shrink-0"
                          size="lg"
                          color={isPerilakuKerjaCompleted ? 'white' : 'blue'}
                          disabled={
                            isNaN(
                              evaluationDetailData?.data?.workBehavior?.total
                            ) ||
                            evaluationDetailData?.data?.workBehavior?.total <= 0
                          }
                          onClick={() =>
                            handleOpenPerilakuKerja(isPerilakuKerjaCompleted)
                          }
                        >
                          {isPerilakuKerjaCompleted ? 'Ubah' : 'Nilai'}
                          <span className="sr-only"> Perilaku Kerja</span>
                        </Button>
                      </div>

                      {isFilledBySystem && (
                        <div className="py-4 px-3 flex space-x-3 bg-surface-warning-subdued rounded-md items-start">
                          <Icon
                            fontSize="sm"
                            className="!text-xl !leading-5"
                            color="warning"
                          >
                            check_circle
                          </Icon>
                          <Text variant="body-sm">
                            Pengembangan Kompetensi dan Tugas Tambahan
                            dikumpulkan secara opsional oleh guru. Anda tetap
                            dapat periksa di periode ini jika ada yang
                            dikumpulkan.
                          </Text>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="border border-subdued rounded py-6 pl-6 pr-8 space-y-6">
                    <div className="space-x-6 flex items-center">
                      <div className="w-20 h-20 flex-shrink-0 flex items-center justify-center">
                        <CircularProgress
                          value={evaluationDetailData?.data?.document?.progress}
                          maxValue={evaluationDetailData?.data?.document?.total}
                        />
                      </div>
                      <div className="space-y-2 w-full">
                        <Text variant="heading-md">Kelengkapan Dokumen</Text>
                        <Text color="subdued">
                          Periksa untuk Pengembangan Kompetensi dan Tugas
                          Tambahan.
                        </Text>
                      </div>
                      <Link
                        href={`${PENGELOLAAN_KINERJA_KS_PENILAI_PATH}/penilaian-kinerja/${npsn}/${guruPtkId}/kelengkapan-dokumen`}
                        passHref
                      >
                        <Button
                          as="a"
                          className="flex-shrink-0"
                          size="lg"
                          color={isDocumentCompleted ? 'white' : 'blue'}
                          disabled={
                            isNaN(
                              evaluationDetailData?.data?.document?.total
                            ) ||
                            evaluationDetailData?.data?.document?.total <= 0
                          }
                          onClick={() => {
                            trackEvent({
                              activity_type: isDocumentCompleted
                                ? 'penilaian_ks_rincian_click_cek_kelengkapan_dokumen'
                                : 'penilaian_ks_rincian_penilaian_click_periksa',
                              screen_name: 'ks_rincian_penilaian',
                              event_trigger: 'ks_periksa_skp',
                            })
                          }}
                        >
                          {isDocumentCompleted ? 'Cek' : 'Periksa'}
                          <span className="sr-only"> Kelengkapan Dokumen</span>
                        </Button>
                      </Link>
                    </div>

                    {isFilledBySystem && (
                      <div className="py-4 px-3 flex space-x-3 bg-surface-warning-subdued rounded-md items-start">
                        <Icon
                          fontSize="sm"
                          className="!text-xl !leading-5"
                          color="warning"
                        >
                          check_circle
                        </Icon>
                        <Text variant="body-sm">
                          Perilaku Kerja tetap perlu dinilai.
                        </Text>
                      </div>
                    )}
                  </div>

                  {!isPredikatKinerjaEnabled && (
                    <div className="border border-subdued rounded py-6 pl-6 pr-8 space-x-6 flex items-center">
                      <div className="space-y-1 w-full">
                        <Text variant="heading-sm">Perilaku Kerja</Text>
                        <Text color="subdued">
                          Penilaian dapat dilakukan mulai 1 Mei 2024.
                        </Text>
                      </div>
                      <Link
                        href={`${PENGELOLAAN_KINERJA_KS_PENILAI_PATH}/penilaian-kinerja/${npsn}/${guruPtkId}/perilaku-kerja`}
                        passHref
                      >
                        <Button
                          as="a"
                          className="flex-shrink-0"
                          color="blue"
                          disabled={true}
                          size="lg"
                        >
                          Nilai
                        </Button>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          </section>
        </section>
      </div>

      {isPredikatKinerjaEnabled && !alwaysHideGuidePerilakuKerjaDialog && (
        <GuidePerilakuKerjaDialog
          hasCheckbox={true}
          isOpen={showGuidePerilakuKerjaDialog}
          onConfirm={handleConfirmGuidePerilakuKerjaDialog}
        />
      )}
    </div>
  )
}

type FinalPredicateProps = {
  isLoading: boolean
  workBehaviorResult: TWorkBehaviorResult | ''
  performancePracticeResult: TPerformancePracticeResult | ''
  predicateRecommendationResult: TPredicateRecommendationResult | ''
}
function FinalPredicate({
  isLoading,
  workBehaviorResult,
  performancePracticeResult,
  predicateRecommendationResult,
}: FinalPredicateProps) {
  const [openDialog, setOpenDialog] = useState(false)

  const workBehaviorResultText = WORK_BEHAVIOR_RESULT_TEXT[workBehaviorResult]
  const performancePracticeResultText =
    PERFORMANCE_PRACTICE_RESULT_TEXT[performancePracticeResult]
  const predicateRecommendationResultText =
    PREDICATE_RECOMMENDATION_RESULT_TEXT[predicateRecommendationResult]

  return (
    <>
      <div>
        <dl className="px-6 pt-4 pb-3 rounded-t bg-white">
          <Text variant="helper" color="subdued" as="dt">
            Rating Praktik Kinerja
          </Text>
          {isLoading ? (
            <Shimmer height={24} width={228} className="mb-3" />
          ) : (
            <Text
              variant={!!performancePracticeResultText ? 'action' : 'body'}
              color={!!performancePracticeResultText ? 'default' : 'disabled'}
              className="mb-3"
            >
              {performancePracticeResultText ?? '-'}
            </Text>
          )}
          <Text variant="helper" color="subdued" as="dt">
            Rating Perilaku Kerja
          </Text>
          {isLoading ? (
            <Shimmer height={24} width={228} />
          ) : (
            <Text
              variant={!!workBehaviorResultText ? 'action' : 'body'}
              color={!!workBehaviorResultText ? 'default' : 'disabled'}
              as="dd"
            >
              {workBehaviorResultText ?? '-'}
            </Text>
          )}
        </dl>
        <div className="bg-surface-selected-default rounded-b px-6 py-3">
          <dl className="mb-3">
            <Text variant="helper" color="subdued" as="dt">
              Rekomendasi Predikat Kinerja
            </Text>

            {isLoading ? (
              <Shimmer height={24} width={228} className="mb-3" />
            ) : (
              <Text as="dd" className="uppercase" variant="action">
                {predicateRecommendationResultText ?? '-'}
              </Text>
            )}
          </dl>
          <Button
            size="sm"
            variant="ghost"
            className="!px-0 space-x-1"
            aria-haspopup={true}
            aria-expanded={openDialog}
            onClick={() => setOpenDialog(true)}
          >
            <Icon fontSize="sm" color="informational">
              help
            </Icon>
            <span>Tentang Predikat Kinerja</span>
          </Button>
        </div>
      </div>

      <Dialog size="lg" autoFocus={false} isOpen={openDialog}>
        <DialogOverlay />
        <DialogContent>
          <DialogHeader title="Predikat Kinerja" />
          <div className="p-6 flex space-x-12">
            <section className="flex flex-col max-w-xs space-y-3 justify-between">
              <div className="space-y-3">
                <Text as="h3" variant="heading-lg" className="!text-black">
                  Bagaimana Predikat Kinerja ditetapkan?
                </Text>
                <Text variant="body">
                  Predikat Kinerja ditetapkan berdasarkan
                  <strong> Kuadran Kinerja Pegawai </strong>
                  yang terdiri atas Rating Praktik Kinerja dan Rating Perilaku
                  Kerja.
                </Text>
                <Button
                  as="a"
                  className="!px-0 space-x-1 !inline-flex"
                  href="https://pusatinformasi.guru.kemdikbud.go.id/"
                  size="lg"
                  target="_blank"
                  variant="ghost"
                >
                  <span>Selengkapnya tentang Predikat Kinerja</span>
                  <Icon fontSize="sm" color="informational">
                    chevron_right
                  </Icon>
                </Button>
              </div>
              <div className="p-4 space-y-4 rounded bg-cyan-5">
                <Text variant="body-sm">
                  Panduan di atas <strong>hanya</strong> berlaku untuk satuan
                  pendidikan dan merupakan kesepakatan antara Kemendikbudristek
                  dengan KemenPANRB sebagai adaptasi PermenPANRB No. 6 Tahun
                  2022.
                </Text>

                <Button
                  as="a"
                  className="whitespace-nowrap !text-gray-90 !inline-block"
                  color="white"
                  fullWidth={false}
                  href={`${process.env.NEXT_PUBLIC_STATIC_GCS_CDN}/pengelolaan-kinerja/Permen%20PANRB%20No.%206%20Tahun%202022.pdf`}
                  size="md"
                  target="blank"
                  type={undefined}
                  variant="ghost"
                >
                  Baca peraturan
                </Button>
              </div>
            </section>

            <section className="border border-gray-30 rounded">
              <div className="space-y-2 p-4">
                <Text as="h3" variant="heading-sm" className="!text-black">
                  Kuadran Kinerja Pegawai
                </Text>
                <Text variant="body-sm">
                  Rating Praktik Kinerja dan Rating Perilaku Kerja dapat Anda
                  ubah di tahap Penetapan Predikat Kinerja.
                </Text>
              </div>

              <hr className="border-gray-30" />

              <figure role="group" className="mb-4 p-4">
                <Image
                  src="/images/pengelolaan-kinerja/kuadran-kinerja-pegawai.png"
                  alt="Kuadran penentuan predikat kinerja berdasarkan rating observasi kelas dan perilaku kerja. Dideskripsikan lebih rinci di bawah"
                  width={642}
                  height={509}
                />
                <figcaption className="sr-only">
                  <ul>
                    <li>
                      Jika rating observasi kelas Di Atas Ekspektasi dan rating
                      perilaku kerja Di Bawah Ekspektasi, maka predikatnya
                      adalah Kurang
                    </li>
                    <li>
                      Jika rating observasi kelas Di Atas Ekspektasi dan rating
                      perilaku kerja Sesuai Ekspektasi, maka predikatnya adalah
                      Baik
                    </li>
                    <li>
                      Jika rating observasi kelas Di Atas Ekspektasi dan rating
                      perilaku kerja Di Atas Ekspektasi, maka predikatnya adalah
                      Sangat Baik
                    </li>
                    <li>
                      Jika rating observasi kelas Sesuai Ekspektasi dan rating
                      perilaku kerja Di Bawah Ekspektasi, maka predikatnya
                      adalah Kurang
                    </li>
                    <li>
                      Jika rating observasi kelas Sesuai Ekspektasi dan rating
                      perilaku kerja Sesuai Ekspektasi, maka predikatnya adalah
                      Baik
                    </li>
                    <li>
                      Jika rating observasi kelas Sesuai Ekspektasi dan rating
                      perilaku kerja Di Atas Ekspektasi, maka predikatnya adalah
                      Baik
                    </li>
                    <li>
                      Jika rating observasi kelas Di Bawah Ekspektasi dan rating
                      perilaku kerja Di Bawah Ekspektasi, maka predikatnya
                      adalah Sangat Kurang
                    </li>
                    <li>
                      Jika rating observasi kelas Di Bawah Ekspektasi dan rating
                      perilaku kerja Sesuai Ekspektasi, maka predikatnya adalah
                      Butuh Perbaikan
                    </li>
                    <li>
                      Jika rating observasi kelas Di Bawah Ekspektasi dan rating
                      perilaku kerja Di Atas Ekspektasi, maka predikatnya adalah
                      Butuh Perbaikan
                    </li>
                  </ul>
                </figcaption>
              </figure>
            </section>
          </div>
          <DialogFooter>
            <Button color="blue" size="lg" onClick={() => setOpenDialog(false)}>
              Saya Mengerti
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
